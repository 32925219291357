import { addAutocomplete } from '../autocomplete'
import { DOMReady } from '../lib/domready'

function getTrackerString(text, type) {
  return `search_text=${text}&search_type=${type}`
}

function handleCategorySearch(baseUrl, input, selection) {
  const id = selection.id
  const text = encodeURIComponent(selection.text)
  const tracker = getTrackerString(text, 'category')
  const paramName = input.dataset.paramName
  window.location = `${baseUrl}?${paramName}=${id}&${tracker}`
}

function handleTopSearch(baseUrl, input, selection) {
  const text = encodeURIComponent(selection.text)
  const id = selection.id.split(':')[1]
  const tracker = getTrackerString(text, 'top-search')
  window.location = `${baseUrl}/${id}?${tracker}`
}

function handlePlainTextSearch(baseUrl, input, selection) {
  const raw = selection ? selection.id.split(':')[1] : input.value
  const text = encodeURIComponent(raw)
  const tracker = getTrackerString(text, 'user-entry')
  const paramName = input.dataset.plainName
  window.location = `${baseUrl}?${paramName}=${text}&${tracker}`
}

function handleLocality(input, selection) {
  const id = selection.id.split(':')[1]
  const text = encodeURIComponent(selection.text)
  const url = input.dataset.localityBase.replace('__slug__', id)
  const tracker = getTrackerString(text, 'locality')
  window.location = `${url}?${tracker}`
}

function handleArbitraryUrl(baseUrl, input, selection) {
  window.location = selection.id.split(':')[1]
}

function handleSearch() {
  const input = document.querySelector('#header-search')
  if (!input) {
    return
  }

  const dataSource = (term) => {
    if (input.dataset.initialId && 0 === term.length) {
      return Promise.resolve([
        {
          id: input.dataset.initialId,
          text: input.dataset.initialLabel,
          separator: false,
        },
      ])
    } else {
      const url = `${input.dataset.route}?term=${term}`
      return fetch(url)
        .then((res) => res.json())
        .catch((err) => console.error('Error while fetching autocomplete', err))
    }
  }
  const onSelect = (el) => {
    const baseUrl = input.dataset.redirect
    if (el.id.startsWith('s:')) {
      handleTopSearch(baseUrl, input, el)
    } else if (el.id.startsWith('p:')) {
      handlePlainTextSearch(baseUrl, input, el)
    } else if (el.id.startsWith('l:')) {
      handleLocality(input, el)
    } else if (el.id.startsWith('u:')) {
      handleArbitraryUrl(baseUrl, input, el)
    } else {
      handleCategorySearch(baseUrl, input, el)
    }
  }
  const options = {
    delay: 300,
    sort: false,
    onFocusIn: true,
    onEnter: () => {
      const baseUrl = input.dataset.redirect
      handlePlainTextSearch(baseUrl, input)
    },
  }

  const wrapper = input.parentNode
  const button = wrapper.querySelector('button[type=submit]')
  button.addEventListener('click', (e) => {
    e.preventDefault()
    const baseUrl = input.dataset.redirect
    if (!input.value) {
      window.location = baseUrl
      return false
    }
    handlePlainTextSearch(baseUrl, input)
    return false
  })

  addAutocomplete(input, dataSource, onSelect, options)
}

DOMReady(handleSearch, ['SearchBarLoaded'])
